<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="轉倉列表" :columns="fields" :data="transferOrders" actions="view,edit,search,refresh" @view="getTransferOrder" @edit="archiveTransferOrder">
				<template v-slot:merchant="{ row }"> {{ row.merchant.name['tc'] }}</template>
				<template v-slot:fromLocation="{ row }"> {{ row.fromLocation.name['tc'] }}</template>
				<template v-slot:toLocation="{ row }"> {{ row.toLocation.name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="adjustmentStatusName(row.status).color"> {{ adjustmentStatusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ transferOrder.id ? '修改' : '新增' }}轉倉</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="transferOrder.merchant" :options="merchants" @input="transferOrder.merchantId = transferOrder.merchant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>

			<div v-if="transferOrder.merchant.id" class="flex space-x-2">
				<label for="name">位置類別</label>
				<n-select v-model="transferOrder.fromLocation" :options="locationByMerchantId(transferOrder.merchant.id)" @input="transferOrder.fromLocationId = transferOrder.fromLocation" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>
			<div v-if="transferOrder.merchant.id" class="flex space-x-2">
				<label for="name">位置類別</label>
				<n-select v-model="transferOrder.toLocation" :options="locationByMerchantId(transferOrder.merchant.id)" @input="transferOrder.toLocationId = transferOrder.toLocation" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>
			<div v-if="transferOrder.items.length > 0" class="space-y-2">
				<p class="text-xl">商品轉倉列表</p>
				<div v-for="(item, index) in transferOrder.items" :key="(item, index)">
					<div class="flex space-x-2">
						<label for="product">商品</label>
						<n-select v-model="item.product" :options="catalogByMerchantId(transferOrder.merchant.id, 'PRODUCT')" @input="item.productId = item.product.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商品"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="variant">變化</label>
						<n-select v-model="item.variant" :options="catalogByMerchantId(transferOrder.merchant.id, 'VARIANT')" @input="item.variantId = item.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="number">數量</label>
						<input type="number" v-model.number="item.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇數量" />
					</div>
				</div>
			</div>

			<div v-if="transferOrder.merchant.id" class="space-y-2">
				<p class="text-xl">加入新商品</p>
				<div class="flex space-x-2">
					<label for="product">商品</label>
					<n-select
						v-model="transferItem.product"
						:options="catalogByMerchantId(transferOrder.merchant.id, 'PRODUCT')"
						@input="
							transferItem.productId = transferItem.product.id
							transferItem.variant = ''
						"
						:get-option-label="option => option.name['tc']"
						class="w-full h-full text-base"
						placeholder="請選擇商品"
					></n-select>
				</div>
				<div class="flex space-x-2">
					<label for="variant">變化</label>
					<n-select v-model="transferItem.variant" :options="catalogByMerchantId(transferOrder.merchant.id, 'VARIANT')" @input="transferItem.variantId = transferItem.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
				</div>

				<div class="flex space-x-2">
					<label for="number">數量</label>
					<input type="number" v-model.number="transferItem.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
				</div>

				<n-button @onClick="addTransferItem(transferItem)">加入新商品</n-button>

				<div class="flex space-x-2">
					<label for="status">轉倉狀態</label>
					<n-select v-model="transferOrder.status" :options="adjustmentStatusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>

				<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

				<div class="flex space-x-2">
					<n-button v-if="transferOrder.id" @onClick="$router.go(0)" color="danger">返回</n-button>
					<n-button @onClick="transferOrder.id ? updateTransferOrder(transferOrder) : createTransferOrder(transferOrder)">{{ transferOrder.id ? '修改轉倉' : '加入轉倉' }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { transferOrder, currentTransferItem } from '@/model/transferOrder'

export default {
	name: 'Home',
	data() {
		return {
			transferItem: currentTransferItem(),
			transferOrder: transferOrder(),
			fields: [
				{
					label: '編號',
					prop: 'referenceId',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchant',
					sortable: true,
				},
				{
					label: '出庫位置',
					prop: 'fromLocation',
					sortable: true,
				},
				{
					label: '入庫位置',
					prop: 'toLocation',
					sortable: true,
				},
				{
					label: '狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions', 'adjustmentStatusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			'currentMerchant',
			// location
			'locations',
			'locationByMerchantId',
			'locationName',
			// catalog
			'catalog',
			'catalogMerchant',
			'catalogByMerchantId',
			'catalogItem',
			// inventory
			'inventories',
			'currentInventory',
			// adjustmentOrder
			// transferOrder
			'transferOrders',
			'currentTransferOrder',
			// state
			'statusName',
			'adjustmentStatusName',
		]),
	},
	methods: {
		async addTransferItem(transferItem) {
			if (!transferItem.product) return
			if (!transferItem.variant) return
			await this.transferOrder.items.push(transferItem)
			this.transferItem = await currentTransferItem()
		},

		async createTransferOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.fromLocationId = data.fromLocation
				data.toLocationId = data.toLocation
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				await this.$store.dispatch('createTransferOrder', { transferOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getTransferOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getTransferOrder', { id })
				this.transferOrder = this.currentTransferOrder
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveTransferOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveTransferOrder', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateTransferOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.fromLocationId = data.fromLocation.id
				data.toLocationId = data.toLocation.id
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				this.error = ''
				await this.$store.dispatch('updateTransferOrder', { id: data.id, transferOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
