export function transferOrder() {
	return {
		merchant: [],
		fromLocation: [],
		toLocation: [],
		referenceId: '',
		items: [],
		status: '',
	}
}

export function currentTransferItem() {
	return {
		product: '',
		variant: '',
		quantity: 0,
	}
}
